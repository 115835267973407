import neoSwitch from "@/components/toggle-switch";
import {mapGetters} from "vuex";
import Loader from "@/components/loader";
const monitoringTimeline = () => import("@/components/monitoring-timeline");

export default {
    components: {
        neoSwitch,
        "neo-loader": Loader,
        monitoringTimeline,
    },
    props: {
        result: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
            default: null,
        },
        showHistory: {
            type: Boolean,
            default: false,
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        isAllRunQueries: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            timeline: [],
        };
    },
    methods: {
        showMonitoringHistory(result, index) {
            this.$emit("showMonitoringHistory", index);
            // this.timeline = await axios.post("/data-monitoring/monitoring-history", {
            //     doc_id: this.result.api_all_data.monitoring_id,
            // });
        },
    },
    computed: {
        ...mapGetters([]),
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
    },
};
